import '@stripe/stripe-js';
import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';

import ConfirmationDialog from '../confirmation-dialog';
import useAuth from '../../hooks/useAuth';

import AccountDialog from '../account-dialog';

import Logo from '../../res/logo-text.svg';
import * as classes from './header.module.scss';

import { CREATE_CUSTOMER_PORTAL_URL } from '../../config';

const TERMS_STORAGE_KEY = 'hasAcceptedTermsAndConditions';

function TermsAndConditionsConfirmationDialog(props) {
  return <ConfirmationDialog {...props} />;
}

function DenseAppBar() {
  const [user, signIn, signOut] = useAuth();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);


  const handleAccountClick = (e) => {
    // if new user display policy confirmation prompt
    // else if not logged in then display login prompt
    // else display options to logout, show profile, ect...
    const hasAcceptedTermsAndConditions = Boolean(localStorage.getItem(TERMS_STORAGE_KEY));
    if (!hasAcceptedTermsAndConditions) {
      return setIsTermsDialogOpen(true);
    } else if (!user) {
      return signIn();
    } else {
      return setAccountMenuAnchorEl(e.currentTarget);
    }
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleAccountSignOutClick = () => {
    handleAccountMenuClose();
    signOut();
  };

  const handleAccountSettingsClick = () => {
    navigate('/account');
  };

  const handleTermsDialogClose = () => {
    setIsTermsDialogOpen(false);
  };

  const handleTermsDialogAccept = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(TERMS_STORAGE_KEY, true);
      handleTermsDialogClose();
      signIn();
    }
  };

  // TODO: make this more performant.
  const handleManageSubscriptionClick = async () => {
    const portalUrl = await createCustomerPortal(user);
    window.open(portalUrl);
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed' classes={{
        colorPrimary: classes.appbarColorPrimary
      }} elevation={0}>
        <Toolbar variant='dense'>
          <Link to='/' className={classes.brand}>
            <div className={classes.brandIcon}>
              <Logo width='100%' height='25px' />
            </div>
          </Link>
          <div className={classes.appbarLink}>
            <Link to='/pricing'>Upgrade</Link>
          </div>
          {/* <div className={classes.appbarLink}>
            <Link to='/help'>Help</Link>
          </div> */}
          <div className={classes.accountButtonContainer}>
            <IconButton
              aria-label='User Account'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleAccountClick}
              color='inherit'
              className={classes.accountButton}
            >
              {user ? <Avatar classes={{ root: classes.avatarRoot}} src={user.photoURL} /> : <AccountCircle />}
            </IconButton>
            <Menu
              id='account-menu'
              anchorEl={accountMenuAnchorEl}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(accountMenuAnchorEl)}
              onClose={handleAccountMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <AccountDialog
                user={user}
                onManageSubscriptionClick={handleManageSubscriptionClick}
                onAccountSettingsClick={handleAccountSettingsClick}
                onSignOutClick={handleAccountSignOutClick}
              />
            </Menu>
            <TermsAndConditionsConfirmationDialog
              isOpen={isTermsDialogOpen}
              onClose={handleTermsDialogClose}
              onAccept={handleTermsDialogAccept}
              title={`Terms and Conditions`}
              content={
                <span>
                    By creating an account, you agree to the TabManager.io{' '}
                  <a href='/terms' target='_blank' rel='noreferrer'>Terms of Service</a> and{' '}
                  <a href='/privacy' target='_blank' rel='noreferrer'>Privacy Policy</a>.
                </span>
              }
              acceptButtonTitle='Agree'
            />
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar variant='dense' disableGutters />
    </div>
  );
}

export default class Header extends React.Component {
  render() {
    return (
      <>
        <DenseAppBar />
      </>
    );
  }
}

async function createCustomerPortal(user) {
  const response = await fetch(CREATE_CUSTOMER_PORTAL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uid: user.uid,
    })
  });

  if (!response.ok) {
    throw Error(`Network response was not ok: ${response.statusText}`);
  }

  const json = await response.json();
  return json.sessionUrl;
}