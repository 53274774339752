// extracted by mini-css-extract-plugin
export const accountDialog = "account-dialog-module--account-dialog--2zbWE";
export const accountContent = "account-dialog-module--account-content--1NozI";
export const contentTop = "account-dialog-module--content-top--18p8y";
export const accountAvatarContainer = "account-dialog-module--account-avatar-container--qFFoV";
export const accountAvatar = "account-dialog-module--account-avatar---MEbo";
export const userDetails = "account-dialog-module--user-details--1Cj1-";
export const headerOverflow = "account-dialog-module--header-overflow--5gL6o";
export const userDetailsHeader = "account-dialog-module--user-details-header--26_Iw";
export const userDetailsSubheader = "account-dialog-module--user-details-subheader--2JmHe";
export const accountActionContainer = "account-dialog-module--account-action-container--1SSWA";