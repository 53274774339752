import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

export default function ConfirmationDialog(props) {
  const { isOpen, onClose, onAccept, title, content, acceptButtonTitle, ...rest } = props;

  return (
    <div className='confirmation-dialog'>
      <Dialog open={isOpen} onClose={onClose} {...rest}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onAccept} color='primary' variant='contained' autoFocus>
            {acceptButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.any,
  acceptButtonTitle: PropTypes.string,
};
