/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
/* import Favicon from "../images/favicon.ico" */

const defaultTitle = `Best Tab Manager Extension for Chrome`;
const defaultDescription = `An easy to use Google Chrome tab manager for organizing your browser tabs, windows and sessions.`;

const defaultKeywords = [
  'google tab manager',
  'chrome tab manager',
  'chrome tab groups',
  'tab manager',
  'windows tab manager',
  'chrome window manager',
  'window manager',
  'session manager',
  'best tab manager chrome',
  'window tabs',
  'chrome extension',
  'cluster tab manager',
  'tab manager plus',
  'tab suspender',
  'extension for chrome',
  'window chrome extension',
  'browser tab manager',
];

function SEO({ description, lang, meta, keywords, title, noIndex } = {}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;

  if (noIndex) {
    meta = [{ name: 'robots', content: 'noindex' }, ...meta];
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)
      }
    /*       link={[
            { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${Favicon}` },
            { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${Favicon}` },
            { rel: 'shortcut icon', type: 'image/png', href: `${Favicon}` },
          ]} */
    />
  );
}

SEO.defaultProps = {
  title: defaultTitle,
  lang: `en`,
  meta: [],
  keywords: defaultKeywords,
  description: defaultDescription,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  noIndex: PropTypes.bool,
};

export default SEO;
