import { firebaseConfig, firebaseEmulatorConfig, SubscriptionPlan } from './config';
import firebase from 'firebase/app';
import 'firebase/auth';

const isDevelopment = process.env.NODE_ENV === 'development';

class SubscriptionDetail {
  constructor({ plan = SubscriptionPlan.FREE, endDate = 0 } = {}) {
    const isFree = plan === SubscriptionPlan.FREE || (endDate < Date.now());
    const isAdvanced = SubscriptionPlan.ADVANCED && (endDate > Date.now());
    this.isFree = isFree;
    this.isAdvanced = isAdvanced;
    this.plan = isAdvanced ? SubscriptionPlan.ADVANCED : SubscriptionPlan.FREE;
    this.endDate = endDate;
  }
}

class UserDetail {
  constructor({ uid, displayName, email, photoURL, subscription }) {
    this.uid = uid;
    this.displayName = displayName;
    this.email = email;
    this.photoURL = photoURL;
    this.subscription = new SubscriptionDetail(subscription);
  }
}

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

const { auth } = firebaseEmulatorConfig;

// if (isDevelopment) {
//   firebase.auth().useEmulator(auth);
// }

export function onAuthStateChanged(callback) {
  return firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) {
      return callback(null);
    }
    const subscription = await getSubscription(user);
    return callback(new UserDetail({
      ...user,
      subscription,
    }));
  });
}

export async function getUser({ forceRefresh = false } = {}) {
  const user = firebase.auth().currentUser;
  if (!user) {
    return null;
  }
  const subscription = forceRefresh ? await getSubscription(user, { forceRefresh }) : await getSubscription(user);
  return new UserDetail({ ...user, subscription });
}

export function signIn() {
  // if (isDevelopment) {
  //   const { testEmail, testPassword } = firebaseEmulatorConfig;
  //   return firebase.auth().signInWithEmailAndPassword(testEmail, testPassword);
  // }
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
}

export function signOut() {
  firebase.auth().signOut();
}

async function getSubscription(user, { forceRefresh = false } = {}) {
  if (!user) {
    return new SubscriptionDetail();
  }
  const idToken = await user.getIdTokenResult(forceRefresh);
  return new SubscriptionDetail(idToken.claims.subscription);
}
