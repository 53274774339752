import React from 'react';
import PropTypes from "prop-types";
import Header from './header';
import Footer from './footer';
import SEO from './seo';

import * as classes from './layout.module.scss';

const Layout = ({ children, title, description, keywords, noIndex }) => {
  return (
    <>
      <SEO title={title} description={description} keywords={keywords} noIndex={noIndex}/>
      <div className={classes.site}>
        <Header />
        <main className={classes.content} role='main'>
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
  noIndex: PropTypes.bool
};

export default Layout;