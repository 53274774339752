import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';

import * as classes from './footer.module.scss';

function Footer() {

  const getYear = () => {
    return new Date().getFullYear();
  }
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container>

          <Grid item xs={6} sm={3}>
            <div className={classes.category}>
              <div className={classes.categoryTitle}>COMPANY</div>
              <Link to='/privacy'>Privacy Policy</Link>
              <Link to='/terms'>Terms of Service</Link>
              {/* <Link to='/terms'>Terms</Link> */}
              <a href='email:support@tabmanager.io'>Contact Us</a>
            </div>
          </Grid>

          <Grid item xs={6} sm={3}>
            <div className={classes.category}>
              <div className={classes.categoryTitle}>PRODUCT</div>
              {/* <Link to='/privacy'>Features</Link> */}
              <Link to='/pricing'>Upgrade</Link>
              <Link to='/features'>Features</Link>
              <Link to='/reviews'>Reviews</Link>
              <Link to='/changelog'>Changelog</Link>
              {/* <Link to='/faq'>FAQ</Link> */}
            </div>
          </Grid>

          {/* <Grid item xs={6} sm={3}>
            <div className={classes.category}>
              <div className={classes.categoryTitle}>RESOURCES</div>
              <Link to='/privacy'>FAQ</Link>
              <Link to='/privacy'>Get Help</Link>
              <Link to='/privacy'>Blog</Link>
            </div>
          </Grid> */}

        </Grid>
        {/* <div>
          © Copyright {getYear()} TABMANAGER.IO. All rights reserved. Various trademarks held by their respective owners.
        </div> */}
      </Container>
    </footer>
  );
}

export default Footer;
