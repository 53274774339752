import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import DialogButton from './dialog-button';

import * as classes from './account-dialog.module.scss';

function AccountDialog({ user, onManageSubscriptionClick, onAccountSettingsClick, onSignOutClick, forwardedRef }) {
  if (!user) {
    return null;
  }

  const { displayName, email, photoURL, subscription: { plan, isAdvanced } } = user;

  return !user ? null : (
    <div className={classes.accountDialog} ref={forwardedRef}>
      <div className={classes.accountContent}>
        <div className={classes.contentTop}>

          <div className={classes.accountAvatarContainer}>
            <Avatar className={classes.accountAvatar} src={photoURL} />
          </div>

          <div className={classes.userDetails}>
            <div className={`${classes.userDetailsHeader} ${classes.headerOverflow}`}>
              {displayName}
            </div>
            <div className={`${classes.userDetailsSubheader} ${classes.headerOverflow}`}>{email}</div>
            <div style={{ marginTop: 6 }}>
              <Chip
                label={<span style={{ textTransform: 'capitalize' }}>{plan}</span>}
                color='primary'
                size='small'
              />
            </div>
          </div>
        </div>

        <Divider orientation='horizontal' style={{ marginTop: 32 }} />

        <div className={classes.accountActionContainer}>
          { isAdvanced && <DialogButton onClick={onManageSubscriptionClick}>Manage your Subscription</DialogButton> }
          <DialogButton onClick={onAccountSettingsClick}>Account Settings</DialogButton>
          <DialogButton onClick={onSignOutClick}>Sign out</DialogButton>
        </div>

      </div>
    </div>
  );
}

export default React.forwardRef((props, ref) => {
  return <AccountDialog {...props} forwardedRef={ref} />;
});